.deviceConnectionsGrid {
  display: grid;
  grid-template-columns: 22rem 1fr;
  gap: var(--spacing-md);
  height: 100%;
  margin: 0 calc(var(--padding-xxs) * -1);
}

.deviceConnectionsCardHeader {
  --_paddng-top: calc(var(--padding-xs) - var(--padding-xxs));

  display: flex;
  gap: var(--spacing-md);
  align-items: center;
  justify-content: space-between;
  height: calc(2.5rem + var(--_paddng-top));
  padding: var(--_paddng-top) var(--padding-xs) 0;
  color: var(--color-on-surf);
}

.newConnections {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: var(--spacing-sm);
  overflow: hidden;
}

.newConnectionsContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  min-height: 100%;
  overflow-y: auto;
}

.newConnectionCard {
  display: grid;
  grid-auto-rows: min-content;
  gap: var(--spacing-sm);
}

.cardIcon {
  color: var(--color-on-surf-variant);
}

.allDevicesContainer {
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: var(--spacing-sm);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.deviceConnectionsCardHeaderFilters {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
  justify-content: space-between;
}

.devicesContent {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: var(--spacing-sm);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.devicesContentFooter {
  align-self: flex-end;
  padding-bottom: 0;
}
