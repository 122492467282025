@use 'th-common/styles/index';

.vehicleDatabasePanel {
  --_header-height: 3rem;

  position: relative;
  display: grid;
  grid-template-rows: var(--_header-height) minmax(0, 1fr);
  gap: index.$spacing-sm;
  height: 100%;
  padding-bottom: index.$padding-xxs !important;
}

.headerContainer {
  height: var(--_header-height);
}

.headerRow {
  display: flex;
  gap: index.$spacing-sm;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.content {
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
  width: 100%;
  height: 100%;
}
