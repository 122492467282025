@use 'th-common/styles/index';

.container {
  flex: none;
  min-height: 3.5rem;
  overflow: auto;

  &_open {
    flex: 1 1 auto;
  }
}

.eventListTable {
  --th-table-background: var(--color-surf-container-high);
}

.eventChip {
  min-width: 4rem;
  color: var(--color-surf-inverse) !important;
  text-transform: capitalize;
  background-color: var(--color-surf-container-high) !important;
  border: 1px solid var(--color-surf-inverse) !important;

  &_active {
    color: var(--color-on-surf-inverse) !important;
    background-color: var(--color-surf-inverse) !important;
  }
}
