@use 'th-common/styles/index';

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: index.$spacing-md;
  align-items: center;

  > *:not(.autoRefresh) {
    width: min-content !important;
  }
}
