@use 'th-common/styles/index';

.panelContainer {
  --_header-height: 2.5rem;

  position: relative;
  display: grid;
  grid-template-rows: var(--_header-height) minmax(0, 1fr);
  gap: index.$spacing-sm;
  height: 100%;
  padding-bottom: 0 !important;
}

.filtersContainer {
  display: flex;
  gap: index.$spacing-sm;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.filtersContainerRight {
  display: flex;
  gap: index.$spacing-sm;
  align-items: center;
}

.footer {
  align-self: flex-end;
  padding-bottom: 0;
}
