@use 'th-common/styles/index';

.container {
  flex: 0 0 auto;
  overflow: visible;
}

.dataPanelWrapper {
  overflow: auto;
  color: var(--color-on-surf-variant);
}

.dataItem {
  display: flex;
  justify-content: space-between;
  padding: var(--padding-xxs) var(--padding-xxs);
  border-bottom: 0.5px solid var(--color-outline-variant);
}
