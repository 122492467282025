.userGroupItemLabelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.userGroupItemTitle {
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
}

.userGroupItemLabel {
  color: var(--color-on-surf);
}

.deleteBtn {
  padding: 0 !important;
}
