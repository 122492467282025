@use 'th-common/styles/index';

.grid {
  display: flex;
  height: 100%;
  background-color: var(--color-surf-container-low);

  &_sidePanelOpen {
    gap: index.$spacing-xs;
  }
}

.resizerBar {
  display: none;
  background: transparent;
  cursor: col-resize;

  &:hover {
    background: var(--color-primary-container);
    border-radius: 0.25rem;
  }

  &_active {
    display: block;
  }
}

.metaDataPanel {
  height: 100%;
  overflow: visible;

  &_inactive {
    width: 0;
    min-width: 0;
    overflow: hidden;
  }
}

.sidePanel {
  display: flex;
  flex-direction: column;
  gap: index.$spacing-sm;
  overflow: visible;

  &_inactive {
    flex: 0 !important;
    width: 0;
    min-width: 0;
    overflow: hidden;
  }
}

.videoPlayback {
  position: relative;
  display: grid;
  grid-template-rows: 2.5rem minmax(0, 1fr);
  gap: index.$spacing-sm;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  gap: index.$spacing-md;
  align-items: center;
  justify-content: space-between;
  padding: 0 index.$padding-xs;
}

.headerTitleAndFilters {
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, auto);
  gap: index.$spacing-xs;
  align-items: center;
}

.activePanel {
  background-color: var(--color-surf-container-highest) !important;
}
