.modifyUser {
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: var(--spacing-sm);
  width: 24.625rem;
  overflow: hidden;
}

.modifyUserHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
}

.modifyUserContent {
  overflow-y: auto;
}

.modifyUserActions {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
}
