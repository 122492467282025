.userManagementContainer {
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: var(--spacing-sm);
}

.userManagementGrid {
  display: grid;
  grid-template-columns: 25rem 1fr;
  gap: var(--spacing-md);
  margin-top: var(--spacing-sm);
  overflow: hidden;

  &_user {
    grid-template-columns: repeat(2, 25rem);
  }

  &_deviceGroupView {
    grid-template-columns: 25rem 1fr 22.5rem;
  }
}

.groupsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
}

.groupsList {
  overflow-y: auto;
}

.actionBtns {
  display: flex;
  gap: var(--spacing-xs);
}

.actionBtn {
  gap: var(--spacing-xs);
}

.groupsContainer {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: var(--spacing-sm);
  overflow: hidden;
}
